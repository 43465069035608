<template>
    <div class="container" style="height:calc(100vh - 3.5rem)">
        
        <div class="row align-items-center justify-content-center h-100">
            
            <div class="toast-container position-absolute top-0 end-0 p-3">
                
                <div class="toast fade in" role="alert" aria-live="assertive" aria-atomic="true" id="showToast" style="z-index: 11;">
                    <div class="toast-header">
                        <strong class="me-auto">Message</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        {{ message }}
                    </div>
                </div>

            </div>
            
            <div class="col-sm col-lg-4 md-auto">

                <p class="h1 text-center text-white">
                    <i class="bi bi-cloud me-2"></i> FormaCloud
                </p>

                <h4 class="lead text-center text-white">Practical Work on Cloud is easier</h4>
                <br>
                
                <div class="card">
                    <div class="card-header">
                        <ul class="nav nav-tabs card-header-tabs">
                            <li class="nav-item">
                                <a href="#" class="nav-link active">
                                    <i class="bi bi-box me-2"></i> Local
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :href="config.API_URL + '/auth/login-azure'" class="nav-link">
                                    <i class="bi bi-microsoft me-2"></i> Microsoft
                                </a>
                            </li>
                        </ul>
                    </div>
                    <form class="card-body" method="post">
                        <div class="mb-3">
                            <label for="username" class="form-label">E-mail</label>
                            <input type="email" name="username" id="username" class="form-control" v-model="username">
                        </div>
                        <div class="mb-3">
                            <label for="password">Mot de passe</label>
                            <input type="password" name="password" id="password" class="form-control" v-model="password">
                        </div>
                        <button class="btn btn-primary mt-2 w-100" @click.prevent="handleLogin">Login</button>
                    </form>
                    <div class="card-footer">
                        <div class="small text-end">
                            - <i>Powered by AWS & Nes-Architect</i>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</template>

<script setup>
    import config from "../../config/config.js";
    import { ref, onMounted } from "vue"; 
    import { useRouter } from "vue-router";
    import axios from "axios";
    import {Toast} from "bootstrap";

    const router = useRouter();

    let username = ref("");
    let password = ref("");
    let message = ref("");

    async function handleLogin() {
        try{
            let token = (await axios.post("/api/auth/login-local?no-redirect=1",{
                username: username.value,
                password: password.value
            })).data.token;
            document.cookie = `ci2n_token=${token}; path=/;`;
            // router.push("Dashboard.datacenters");
            document.location.href = "/";
        }
        catch(e){
            message.value = (e.response.data.message) ? e.response.data.message : e;
            let toast = new Toast( document.getElementById("showToast") );
            toast.show();
        }
    }

    /**
     * When the route is loaded
     */
    onMounted( async () => {
        
    })
    
</script>

<style scoped>

</style>